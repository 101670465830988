import { Button, Container, Divider, Text, Title } from "@mantine/core";
import { IconArrowNarrowLeft, IconCircleCheck } from "@tabler/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "../../Components/Style/UseStyle";

function Thankyoupage() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const onclick = ()=>{
      navigate("/ArticleCard")
  }
  return (
    <div className={classes.backgroundColor}>
      <Container size="xl" py="xl">
        <div className={classes.uploadfile}>
          <Text align="center" mt={40}>
            <IconCircleCheck
              size={60}
              color="orange"
              style={{ align: "center" }}
            />
          </Text>
          <Title color="orange" align="center" weight={500} mt={13} mb={20}>
            Thank you!
          </Title>
          <Text align="center" size={17} weight={500} mb={40}>
            One of our team members will <br />
            contact you with pricing details.
          </Text>{" "}
          <Divider size="sm" />
          <Text align="center" mt={40}>
            {" "}
            <Button
              size="lg"
              variant="default"
              leftIcon={<IconArrowNarrowLeft size={14} />}
              onClick={onclick}
            >
              Back to Catalogue
            </Button>
          </Text>
        </div>
      </Container>
    </div>
  );
}

export default Thankyoupage;
