import React from "react";
import {
  
  Button,
  Text,
  TextInput,
  Container,
} from "@mantine/core";

import { useForm, zodResolver } from "@mantine/form";

import { Link, useNavigate } from "react-router-dom";
import Bg from "../Image/Bg";
import Logo from "../Logo/Logo";
import ForgotPassSchema from "../ValidationLogic/ForgotPassSchema";
import { useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";

export default function ForgetPass() {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const forgotpassword = useForm({
    validateInputOnChange: true,
    validate: zodResolver(ForgotPassSchema),

    initialValues: {
      email: "",
    },
  });

  return (
    <>
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <div
          style={{ height: "100vh", width: "100%", backgroundColor: "white" }}
        >
          {" "}
          <Logo />
          <Container size={500} style={{ marginTop: "100px" }}>
            <form
              onSubmit={forgotpassword.onSubmit(async (values) => {
                const result = await context.ForgetPassword(values.email);

                if (!result.status) {
                console.log(
                    "Something went Wrong !! please try again letter."
                  );
                  alert("Something went Wrong !! please try again letter.");
                  // navigate("/login");
                } else {
                  // console.log("click", values)
                  navigate("/ForgetPassSub");
                }
              })}
            >
              <Text
                size={40}
                weight={500}
                align="center"
                mt="md"
                mb={10}
                color="#051C48"
              >
                Forgot Password
              </Text>
              <Text size={15}  weight={400} align="center" mb={50} color="#051C48">
                Enter the email associated with your account <br />
                and we'll send an email with instructions to <br /> reset your
                password.
              </Text>

              <TextInput
                label="Email"
                placeholder="Enter your email"
                size="lg"
                required
                radius={7}
                {...forgotpassword.getInputProps("email")}
              />

              <Button
                fullWidth
                mt="lg"
                size="md"
                style={{ backgroundColor: "#E27612" }}
                type="submit"
              >
                Submit
              </Button>
            </form>

            <Text align="center" mt="lg" color="#051C48">
              Don’t have an account?
              <Link
                to="/NewAccount"
                weight={700}
                style={{ textDecoration: "none", marginLeft: "5px" }}
              >
                Contact us
              </Link>
            </Text>
          </Container>
        </div>
        <div style={{ height: "100%", width: "90%" }}>
          <Bg />
        </div>
      </div>
    </>
  );
}
