import React from "react";
import useStyles from "../../../Components/Style/UseStyle";
import editlibicon from "../../../Images/editlibicon.svg";
import deleteicon from "../../../Images/deleteicon.svg";
import {
  Table,
  Text,
  Container,
  Button,
  Badge,
  Avatar,
  Title,
} from "@mantine/core";
import Vectorleftsidearreow from "../../../Images/Vectorleftsidearreow.svg";
import Vectorrightsidearreow from "../../../Images/Vectorrightsidearreow.svg";
import bluedot from "../../../Images/bluedot.svg";
import orangedot from "../../../Images/orangedot.svg";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { IconCirclePlus} from "@tabler/icons";
import "../../../App.css";
const Account = () => {
  const context = useContext(UserContext);
  const { classes } = useStyles();
  const [currentpage, setCurrentpage] = useState(1);
  const [posts, setPosts] = useState();
  const [size, setSize] = useState();
  const [number, setNumber] = useState();

  useEffect(() => {
    const result = async () => {
      const result = await context.getallProfile(currentpage);
      setPosts(result.users);
      setNumber(result.count);
      setSize(result.users.length);
    };
    result();
    // eslint-disable-next-line
  }, []);
  const pageCount = number ? Math.ceil(number / size) : 0;
  const pages = _.range(1, pageCount + 1);
  const navigate = useNavigate();
  const handleclick = async (num) => {
    const g = await context.getallProfile(num);
    setPosts(g.users);
    setCurrentpage(num);
  };
  const handleNext = async (num) => {
    const g = await context.getallProfile(num);
    setPosts(g.users);
    setCurrentpage(num);
  };

  const handlePrev = async (num) => {
    const g = await context.getallProfile(num);
    setPosts(g.users);
    setCurrentpage(num);
  };
  const rows = posts?.map((row) => (
    <tr key={row.id}>
      <td style={{ display: "flex" }}>
        <Avatar size={36} variant="filled" color="cyan" radius={26} mr={6}>
          <Text size={17} color="white" weight={500}>
            {row.fullName.slice(0, 1).charAt(0).toUpperCase()}{" "}
          </Text>
        </Avatar>

        <Text size={18} color="#0B7285" weight={600} style={{ padding: "4px" }}>
          {row.fullName}
        </Text>
      </td>
      <td>
        {row.status === true ? (
        <Badge color="orange" size="lg" radius="lg">
        <img src={orangedot} alt="" srcset="" />{" "}
        <text style={{ marginLeft: "10px", marginRight: "7px" }}>
          {" "}
          Active
        </text>
      </Badge>
    ) : (
      <Badge color="blue" size="lg" radius="lg">
        <img src={bluedot} alt="" srcset="" />{" "}
        <text style={{ marginLeft: "10px" }}> Inactive</text>
      </Badge>
        )}
      </td>
      <td>
        {" "}
        <Text size={18} color="#1098AD" weight={500}>
          {row.email}
        </Text>
      </td>
      <td>
        <div className={classes.emailflex}>
          {" "}
          <Link to={`/Editacc/${row.id}`}>
            {" "}
            <img
              src={editlibicon}
              alt=""
              srcset=""
              style={{
                cursor: "pointer",

                color: "#051C48",
              }}
            />
        
          </Link>
          <div
            style={{ color: "#051C48", cursor: "pointer", marginLeft: "5px", marginRight:"20px" }}
            onClick={async () => {
              const response = window.confirm(
                "Are you sure to delete this User?"
              );
              if (response) {
                await context.UserDeleteByAdmin(row.id, true);
                console.log("delete");
                const g = await context.getallProfile(currentpage);
                setPosts(g.users);
              } else {
                navigate("/Account");
              }
            }}
          >
            {" "}
            <img src={deleteicon} alt="" srcset="" />
          </div>
     
        </div>
      </td>
    </tr>
  ));
  return (
    <>
      {" "}
      <div className={classes.backgroundColor}>
        <Container
          fluid
          py="xl"
          className={classes.inner}
          style={{ padding: "22px" }}
        >
          <div className={classes.navtype} style={{ marginBottom: "10px" }}>
            <Text className={classes.catalogue}>Accounts</Text>
            <Link to="/addaccount">
              {" "}
              <Button
                style={{ backgroundColor: "#E27612", borderRadius: "32px" }}
              >
                <IconCirclePlus />
                <Text color="white" ml={5}>
                  Add Account
                </Text>
              </Button>
            </Link>{" "}
          </div>{" "}
          {rows?.length === 0 ? (
            <Title size={30} align="center" m={100} weight="400">
              No Data
            </Title>
          ) : (
            <div className={classes.contactlist} style={{marginTop:"20px"}}>
                <div style={{ height: "100%", marginTop: "20px" }}>
              <Table
                highlightOnHover
                striped
                fontSize="lg"
                verticalSpacing="md"
                horizontalSpacing="xl"
              >
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Status</th>
                    <th>Email Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </div></div>
          )}
          <div style={{ marginTop: "40px", marginBottom: "10px" }}>
            {rows?.length === 0 ? (
              ""
            ) : (
              <nav className="d-flex justify-content-center">
                {pageCount === 1 ? null : (
                  <ul className="pagination">
                    {currentpage === 1 ? (
                      <li
                        style={{ fontSize: "16px", backgroundColor: "#B0C6F4" }}
                      >
                        <img src={Vectorleftsidearreow} alt="" srcset="" />
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer", fontSize: "16px" }}
                        onClick={() => {
                          handlePrev(currentpage - 1);
                        }}
                      >
                        <img src={Vectorleftsidearreow} alt="" srcset="" />
                      </li>
                    )}
                    {pageCount === 1
                      ? null
                      : pages.map((num) => (
                          <li
                            className={num === currentpage ? "active" : ""}
                            style={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => {
                              handleclick(num);
                            }}
                          >
                            {num}
                          </li>
                        ))}

                    {pages.length === currentpage ? (
                      <li
                        style={{ fontSize: "16px", backgroundColor: "#B0C6F4" }}
                      >
                        <img src={Vectorrightsidearreow} alt="" srcset="" />
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer", fontSize: "16px" }}
                        onClick={() => {
                          handleNext(currentpage + 1);
                        }}
                      >
                        <img src={Vectorrightsidearreow} alt="" srcset="" />
                      </li>
                    )}
                  </ul>
                )}
              </nav>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Account;
