import React, { useEffect, useState } from "react";
import useStyles from "../../../../Components/Style/UseStyle";
import { Text, Card, SimpleGrid, Title, Pagination } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import editicon from "../../../../Images/editicon.svg";
import deletebutton from "../../../../Images/deletebutton.svg";
import CompanycolorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import _ from "lodash";
import "../../../../App.css";

function ColorsCard() {
  const context = useContext(CompanycolorContext);
  const [number, setNumber] = useState();
  const storedPage =
    parseInt(localStorage.getItem("currentColorPage"), 10) || 1;
  const [currentpage, setCurrentpage] = useState(storedPage);
  const [posts, setPosts] = useState();
  const limit = 21;

  useEffect(() => {
    const getcolors = async () => {
      const companycolor = await context.getallcolorsdata(currentpage);
      setPosts(companycolor.companyColors);
      setNumber(companycolor.count);
      // setSize(companycolor.companyColors.length);
    };
    getcolors();
    // eslint-disable-next-line
  }, []);

  const { classes } = useStyles();
  const pageCount = number ? Math.ceil(number / limit) : 0;
  const pages = _.range(1, pageCount + 1);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("currentColorPage", currentpage.toString());
  }, [currentpage]);

  const setPage = async (num) => {
    const g = await context.getallcolorsdata(num);
    setPosts(g.companyColors);
    setCurrentpage(num);
  };

  const features = posts?.map((feature, index) => (
    <Card key={index} className={classes.card}>
      <Card.Section>
        <div
          style={{
            width: "250px",
            height: "82px",
            backgroundColor: `${feature.colorCode}`,
            color: `${feature.colorCode}`,
          }}
        ></div>
      </Card.Section>
      <Text
        mt={5}
        size={15}
        weight={500}
        align="center"
        style={{ color: "#051C48" }}
      >
        {feature.name}
      </Text>
      <div className={classes.cardDisplay}>
        <Link
          style={{ textDecoration: "none", display: "flex" }}
          to={`/Updatecolor/${feature.id}`}
        >
          <img src={editicon} alt="" srcset="" />

          <Text size="sm" mt={4} ml={2} style={{ color: "#051C48" }}>
            Edit
          </Text>
        </Link>

        <div
          style={{ cursor: "pointer", display: "flex" }}
          onClick={async (e) => {
            e.preventDefault();
            const response = window.confirm(
              "Are you sure to delete this color?"
            );
            if (response) {
              const dataarray = await context.deletecolor(feature.id, posts);
              setPosts(dataarray);
            } else {
              navigate("/CompanyColor");
            }
          }}
        >
          <img src={deletebutton} alt="" srcset="" />

          <Text size="sm" style={{ color: "#051C48" }} mt={4} ml={2}>
            Delete
          </Text>
        </div>
      </div>
    </Card>
  ));
  return (
    <div style={{ height: "100%", marginTop: "20px" }}>
      {features?.length === 0 ? (
        <Title size={30} weight={400} align="center" mt={100}>
          No data
        </Title>
      ) : (
        <div style={{ display: "grid", minHeight: "70vh" }}>
          <div>
            <SimpleGrid
              cols={7}
              spacing="md"
              mt={20}
              mb={50}
              breakpoints={[
                { maxWidth: "xl", cols: 7 },
                { maxWidth: "lg", cols: 5 },
                { maxWidth: "md", cols: 4 },
                { maxWidth: "sm", cols: 3 },
                { maxWidth: "xs", cols: 2 },
              ]}
            >
              {features}
            </SimpleGrid>
          </div>{" "}
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "40px",
          marginBottom: "10px",
        }}
      >
        {features?.length === 0 ? (
          ""
        ) : (
          <>
            <Pagination
              page={currentpage}
              onChange={setPage}
              total={pages.length}
              withEdges
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ColorsCard;
