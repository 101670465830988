import React from "react";
import {
  Card,
  Container,
  Loader,
  SimpleGrid,
  Text
} from "@mantine/core";
import useStyles from "../../Components/Style/UseStyle";
import texture from "../../Images/Texture.svg";
import CatelogueContext from "../../ContextFolder/CatelogueContext/CatelogueContext";
import { IconCategory, IconEdit, IconSearch } from "@tabler/icons";
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../App.css";

function MyLibraryuser() {
  const [posts, setPosts] = useState();
  const [loader, setLoader] = useState(false);

  const context = useContext(CatelogueContext);
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const getallUserDesigninMylibraryPage = async () => {
      setLoader(true);
      const result = await context.getallUserDesigninMylibraryPage(userId);

      setPosts(result.designs);
      setLoader(false);
    };

    getallUserDesigninMylibraryPage();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const { classes } = useStyles();

  const features = posts?.map((feature) => {
    let blob = new Blob([feature.patternImage], { type: "image/svg+xml" });
    let url = URL.createObjectURL(blob);

    return (
      <div>
        <Card key={feature.id} shadow="md" radius="md" className={classes.card}>
          <div
            style={{ cursor: "pointer" }}
            className="container"
            onClick={async () => {
              await context.getdesignId(feature.id);
              navigate("/mylibrarydesignedit");
            }}
          >
            <Card.Section className={classes.imageSection}>
              {" "}
              <div
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  height: "100%",
                  backgroundImage: `url(${url})`,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    height: "100%",
                  }}
                  src={texture}
                  alt="not found"
                />
              </div>{" "}
              <div className="overlay"></div>
              <div
                style={{
                  backgroundColor: "#E27612",
                  height: "33px",
                  width: "150px",
                  borderRadius: "5px",
                }}
                className="content"
              >
                {" "}
                <div style={{ marginTop: "4px" }}>
                  {" "}
                  <IconSearch style={{ marginRight: "10px" }} />
                  View Design
                </div>
              </div>
            </Card.Section>
            <Text style={{ color: "#000066", fontSize: "16px" }} weight={400}>
              {feature.title}
            </Text>{" "}
          </div>
          <hr style={{ color: "#A5BBE2" }} />
          <div className={classes.carddisplay}>
            <div className={classes.carddisplay}>
              <IconCategory color="#A5BBE2" />

              <Text
                style={{
                  color: "#000066",
                  fontSize: "14px",
                  marginLeft: "5px",
                }}
                weight={500}
              >
                {feature?.libraries[0]?.name}
              </Text>
            </div>
            <div className={classes.carddisplay}>
              <Link
                style={{
                  textDecoration: "none",
                  display: "flex",
                  color: "black",
                }}
                to={`/mylibraryedit/${feature.id}`}
              >
                <Text
                  size="sm"
                  mr={10}
                  color="#0B7285"
                  style={{ fontSize: "14px" }}
                >
                  Edit
                </Text>
                <IconEdit color="#A5BBE2" />
              </Link>
            </div>
          </div>
        </Card>
      </div>
    );
  });
  return (
    <div>
      {" "}
      <div className={classes.backgroundColor} style={{ height: "100%" }}>
        <Container fluid py="xl" style={{ padding: "22px" }}>
          <Text className={classes.catalogue}> My Library </Text>
          {features?.length === 0 && (
            <p  align="center" style={{marginTop:"100px", fontWeight:"normal", fontSize:"25px"}}>
               Please edit a design and save it to my library
            </p>
          )}
          <div style={{ display: "grid", minHeight: "90vh" }}>
            {loader === true ? (
              <Container py="xl" mt={100}>
                {" "}
                <Loader color="orange" size="xl" variant="dots" />
              </Container>
            ) : (
              <div>
                {features?.length > 0 && (
                  <SimpleGrid
                    cols={4}
                    spacing="lg"
                    mt={20}
                    breakpoints={[
                      { maxWidth: "md", cols: 2 },
                      { maxWidth: "sm", cols: 1 },
                    ]}
                  >
                    {features}
                  </SimpleGrid>
                )}
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default MyLibraryuser;
