import { Text,  Container, Flex } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons";
import Bg from "../Image/Bg";
import Logo from "../Logo/Logo";

export default function ForgetPassSub() {

  return (
    <>
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <div
          style={{ height: "100vh", width: "100%", backgroundColor: "white" }}
        >
          <Logo />
          <Container size={500} style={{ marginTop: "200px" }}>
          <Flex justify={{ sm: "center" }}> <IconCircleCheck size={70} style={{color:"#E27612"}}/></Flex>
            <Text
              order={2}
              size={40}
              color="#051C48"
              weight={500}
              align="center"
              mt="xs"
              mb={10}
              style={{ color: "#E27612" }}
            >
              Check your mail
            </Text>
            <Text size={20} color="#051C48" weight={400} align="center" mb={70}>
              We have sent a password recover <br />
              instructions to your email.
            </Text>

            <Text align="center" mt="md" color="#051C48">
              Did not receive the email? Check your spam <br />
              filter, or try <span  style={{ color: "#E27612" }}> another email address</span>
            </Text>
          </Container>{" "}
        </div>{" "}
        <div style={{ height: "100%", width: "90%" }}>
          <Bg />
        </div>
      </div>
    </>
  );
}
