import React from "react";
import { Flex } from "@mantine/core";

import Newdesigntag from "./Newdesigntag";
import Admindropdownmenu from "./Admindropdownmenu";
function Sideitem() {
  
  return (
    <>
      <Flex
        mih={50}
        gap="sm"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
      >
       {localStorage.getItem("role") === "SuperAdmin"  ?  <Newdesigntag /> : <div></div>}
        <Admindropdownmenu />
      </Flex>
    </>
  );
}

export default Sideitem;
