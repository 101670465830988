import React, { useContext, useEffect, useState } from "react";
import "../../../App.css";
import useStyles from "../../../Components/Style/UseStyle";
import {
  Text,
  TextInput,
  Flex,
  Button,
  SimpleGrid,
  ScrollArea,
  Modal,
  Container,
  Checkbox,
  Input,
} from "@mantine/core";
// import texture from "../../../Images/Texture.svg";
import * as svg from "../Designs/svg";
import { Stage, Layer, Rect } from "react-konva";
import { useNavigate } from "react-router-dom";
import useImage from "use-image";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import { useForm } from "@mantine/form";
import CompanycolorContext from "../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import { useRef } from "react";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import { IconCirclePlus } from "@tabler/icons";
import { useDisclosure } from "@mantine/hooks";

function ChangeColor({ fileSource }) {
  const context = useContext(CatelogueContext);
  const [active, setActive] = useState("");
  const { classes, cx } = useStyles();
  const [libraries, setLibraries] = useState();
  const [valuee, setValue] = useState([]);

  const [colorMap, setColorMap] = useState([]);
  const [opened, { close, open }] = useDisclosure(false);
  const [svgString, setSvgString] = useState("");
  const colors = svg.getColors(svgString);
  const [colorPickerSelectedColor, setColorPickerSelectedColor] =
    useState("#D1C2B6");
  const divRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [first, setfirst] = useState({
    name: "",
    status: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (e) => {
    setfirst({ ...first, [e.target.name]: e.target.value });
  };
  const handleClick = async () => {
    await contextLib.addlibrary(first.name, first.status);
    setfirst({ name: "" });

    const lib = await contextLib?.getlibraryforSomepage();
    setLibraries(lib);
  };

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
    if (fileSource) {
      const fetchSVGString = async () => {
        const mySvgRaw = await fetch(fileSource);
        const mySvgText = await mySvgRaw.text();
        setSvgString(mySvgText);
      };
      fetchSVGString();
    }
    const getlibrary = async () => {
      const lib = await contextLib?.getlibraryforSomepage();
      setLibraries(lib);
    };

    const getcolors = async () => {
      const companycolor = await contextcolor?.getallcolorsdataForsomePages();
      setColordata(companycolor.companyColors);
    };
    getlibrary();
    getcolors();
    // eslint-disable-next-line
  }, [fileSource]);

  const contextcolor = useContext(CompanycolorContext);
  const contextLib = useContext(LibraryContext);
  const [colordata, setColordata] = useState([]);
  // eslint-disable-next-line
  const [scrolled, setScrolled] = useState(false);
  // const a = libraries?.libraries?.map((ui) => {
  //   return { label: `${ui.name}`, value: `${ui.id}` };
  // });

  const filteredColors = colordata?.filter((rawcolor) =>
    rawcolor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const rawcolors = filteredColors?.map((rawcolor) => {
    return (
      <>
        <div>
          <div
            key={rawcolor.id}
            style={{
              display: "flex",
              justifyContent: "normal",
              marginBottom: "5px",
              // marginLeft:"5px"
            }}
          >
            <div
              style={{
                content: `${rawcolor.name}`,
                width: "24px",
                height: "24px",

                borderRadius: "4px",
                // marginLeft:"5px",
                // marginRight: "10px",
                cursor: "pointer",
                backgroundColor: `${rawcolor.colorCode}`,
              }}
              onClick={() => {
                setNewColor(colorPickerSelectedColor, rawcolor.colorCode);
              }}
            ></div>

            <Text ml={5}>{rawcolor.name}</Text>
          </div>
        </div>
      </>
    );
  });
  const modifiedSVG = svg.replaceColors(svgString, colorMap);
  // console.log(modifiedSVG, "modifiedSVG");
  const [image] = useImage(svg.svgToURL(modifiedSVG));
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const showColorPicker = (toggle, oldColor) => {
    setDisplayColorPicker(toggle);
    setColorPickerSelectedColor(oldColor);
  };

  const setNewColor = (oldColor, newColor) => {
    setColorMap({
      ...colorMap,
      [oldColor]: newColor,
    });
  };

  const designdetails = useForm({
    initialValues: {
      name: "",
    },
  });

  const navigate = useNavigate();

  const data = colors?.map((color) => colorMap[color] || color);

  return (
    <>
      <div style={{ display: "flex", minWidth: "100%", height: "100vh" }}>
        <div
          ref={divRef}
          style={{
            width: "70%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Stage
            width={dimensions.width}
            height={dimensions.height}
            // width={window.innerWidth - 550}
            // height={window.innerHeight - 100}
            // scaleX={scale}
            // scaleY={scale}
          >
            <Layer>
              <Rect
                width={dimensions.width}
                height={dimensions.height}
                // width={window.innerWidth + 650}
                // height={window.innerHeight + 500}
                // scaleX={scale}
                // scaleY={scale}
                fillPatternImage={image}
              />
            </Layer>
          </Stage>
        </div>

        {/* <div
          style={{
            background: `url(${texture})`,
            height: "100%",
            position: "absolute",
            width: "70%",
            top: "11%",
            left: "0",
          }}
          alt=""
        /> */}

        <div className={classes.d2c}>
          <div className={classes.colorchange}>
            <Text weight={600} color="#051C48">
              Colors
            </Text>
            <div className="p-2">
              <div style={{ display: "flex" }}>
                {colors.map((color) => {
                  const colorName = colordata.find(
                    (rawcolor) => rawcolor.colorCode === colorMap[color]
                  )?.name;
                  const targetColorCode = color;
                  const editData = colordata.find(
                    (rawcolor) =>
                      rawcolor.colorCode.toLowerCase() ===
                      targetColorCode.toLowerCase()
                  );
                  const finalData = editData ? editData.name : "";

                  return (
                    <div
                      key={color}
                      style={{
                        marginRight: "20px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "58px",
                          height: "58px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        className={cx({ [classes.active2]: color === active })}
                        onClick={() => {
                          showColorPicker(true, color);
                          setActive(color);
                        }}
                      >
                        <div
                          className={cx({ [classes.active]: color === active })}
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            backgroundColor: colorMap[color] || color,
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          marginTop: "2px",
                          wordBreak: "break-word",
                          width: "70px",
                        }}
                      >
                        <Text size={"sm"}>{colorName || finalData}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <Flex justify="space-between">
                <Text weight={600} mb={10} color="#051C48">
                  Choose Color
                </Text>
                <Input
                  type="text"
                  placeholder="Search color..."
                  hidden={!displayColorPicker}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  mb={"md"}
                />
              </Flex>

              {displayColorPicker && (
                <ScrollArea
                  sx={{ height: 150 }}
                  onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    marginRight: "10px",
                  }}
                >
                  <SimpleGrid
                    cols={2}
                    spacing="xs"
                    breakpoints={[
                      { maxWidth: "md", cols: 2 },
                      { maxWidth: "sm", cols: 1 },
                    ]}
                  >
                    {rawcolors}
                  </SimpleGrid>
                </ScrollArea>
              )}
              <form
                onSubmit={designdetails.onSubmit(async (values) => {
                  if (!values.name) {
                    alert("please enter design name!");
                  } else {
                    await context.addDesign(
                      values.name,
                      data,
                      modifiedSVG,
                      valuee
                    );
                    navigate("/ArticleCard");
                  }
                })}
              >
                <div>
                  <Text weight={600} mt={2} mb={4} size={18} color="#051C48">
                    Design Details
                  </Text>
                  <TextInput
                    {...designdetails.getInputProps("name")}
                    label="Name"
                    color="#051C48"
                    mb={7}
                    placeholder="Trellise"
                  />
                  <div>
                    <Text weight={500} mt={10} mb={5} size={15} color="black">
                      Choose Library
                    </Text>

                    <ScrollArea
                      sx={{ height: 170 }}
                      // style={{backgroundColor: "blue",}}
                      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                    >
                      <Checkbox.Group
                        // defaultValue={["react"]}
                        orientation="vertical"
                        spacing="xs"
                        value={valuee}
                        onChange={setValue}
                        mt={2}
                        withAsterisk
                        style={{ backgroundColor: "white" }}
                      >
                        {libraries?.libraries?.map((row) => (
                          <Checkbox
                            ml={10}
                            key={row.id}
                            value={row.id}
                            label={row.name}
                            color="orange"
                          />
                        ))}
                      </Checkbox.Group>
                    </ScrollArea>
                    <div
                      onClick={open}
                      style={{
                        display: "flex",
                        marginLeft: "5px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <IconCirclePlus />
                      <Text color="black">Add Library</Text>
                    </div>

                    {/* {displaylib && (
                      <>
                        {" "}
                    
                          <MultiSelect
                            data={a}
                            placeholder="Choose Library"
                            onChange={setValue}
                          />
                  */}
                  </div>
                </div>
                <Flex justify={{ sm: "center" }}>
                  {" "}
                  <Button
                    fullWidth
                    mt="sm"
                    size="sm"
                    style={{ backgroundColor: "#E27612", marginBottom: "5px" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Flex>
              </form>
              <Modal
                opened={opened}
                onClose={close}
                size="xl"
                title="Add library"
              >
                <Container size={700} py="xl">
                  {" "}
                  <Text>Add Library</Text>
                  <TextInput
                    // sx={{ flex: 1 }}
                    name="name"
                    value={first.name}
                    onChange={handleChange}
                    // {...form.getInputProps("name")}
                    mb={20}
                  />
                  <Button
                    onClick={handleClick}
                    mt="sm"
                    style={{
                      backgroundColor: "#E27612",
                      marginLeft: "90%",
                    }}
                  >
                    Save
                  </Button>
                  {/* </form> */}
                </Container>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeColor;
