import React from "react";
import { Link } from "react-router-dom";
import { Button, Text } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";

function Newdesigntag() {
  return (
    <div>
      <Link to="/UploadFile">
        {" "}
        <Button style={{ backgroundColor: "#E27612", borderRadius: "32px" }}>
          <IconCirclePlus />
          <Text color="white" ml={5} mr={5}>
          New designs
          </Text>
        </Button>
      </Link>
    </div>
  );
}

export default Newdesigntag;
