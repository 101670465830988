import { Button, Container, Text } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";
import { Link } from "react-router-dom";
import useStyles from "../../../Components/Style/UseStyle";
import ColorsCard from "./Colors/ColorsCard";

export default function CompanyColor() {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.backgroundColor}>
        <Container fluid py="xl" style={{ padding: "22px" }}>
          <div className={classes.navtype}>
            <Text className={classes.catalogue}>Company Colors</Text>
            <div>
              <Link to="/Addcolor">
                {" "}
                <Button
                  style={{
                    backgroundColor: "#E27612",
                    borderRadius: "32px",
                    marginRight: "20px",
                  }}
                >
                  <IconCirclePlus />
                  <Text color="white" ml={5} mr={5}>
                    Add Color
                  </Text>
                </Button>
              </Link>
            </div>
          </div>
          <ColorsCard />
        </Container>
      </div>
    </>
  );
}
