import React from "react";
import Nav2menuitems from "./Nav2menuitems";
// import Nav2menuitemsUser from "../../../../User-Category/HeaderUser/Navbar2/Nav2menuitems";
import Nav2menuitemsUser from "../../../RoleWisePagesAndComponents/User-Category/HeaderUser/Navbar2/Nav2menuitems";

function Nav2() {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg "
        style={{ backgroundColor: "#F5F7FB" , height:"50px"}}
      >
        <div >
        {localStorage.getItem("role") === "SuperAdmin"  ?<Nav2menuitems />:
          <Nav2menuitemsUser/>}
        </div>
      </nav>
    </div>
  );
}

export default Nav2;
