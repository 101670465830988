import React from "react";
import Nav1 from "../Header/Navbar1/Nav1";

function Navbar({ setSearchstring, searchstring }) {
  return (
    <div>
      <Nav1 setSearchstring={setSearchstring} searchstring={searchstring}  />
    </div>
  );
}

export default Navbar;
