import React, { useState } from "react";

import {  Text } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "../../Style/UseStyle";
import accountsvg from "../../../../src/Images/accountsvg.svg";
import companycolorsvg from "../../../../src/Images/companycolorsvg.svg";
import category from "../../../../src/Images/category.svg";
import catelogue from "../../../../src/Images/catelogue.svg";

function Nav2menuitems() {
  const uselocation = useLocation();
  const [active, setActive] = useState(0);
  const { classes, cx } = useStyles();
  return (
    <>
      <div
        className={cx({ [classes.mainLinkActive]: active === 0 })}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >  
        <Link
          to="/ArticleCard"
          onClick={() => setActive(1)}
          style={{
            textDecoration: "none",
            display: "flex",
            marginLeft: "24px",
          }}
        >
          <img src={catelogue} alt="" srcset="" />
          <Text
            size={16}
            weight={600}
            ml={5}
            // style={{fontFamily:"Manrope"}}
            color={`${
              uselocation.pathname === "/ArticleCard" ? "#E27612" : "#051C48"
            }`}
          >
            Catalogue
          </Text>{" "}
     
        </Link>
        <Link
          to="/Libraries"
          style={{
            color: "black",
            textDecoration: "none",
            display: "flex",
            marginLeft: "31px",
          }}
          onClick={() => setActive(0)}
        >
          <img src={category} alt="" srcset="" />{" "}
          <Text
            size={16}
            weight={600}
            ml={4}
            color={`${
              uselocation.pathname === "/Libraries" ? "#E27612" : "#051C48"
            }`}
          >
            Libraries
          </Text>
        </Link>
        <Link
          to="/CompanyColor"
          style={{
            color: "black",
            textDecoration: "none",
            display: "flex",
            marginLeft: "29px",
          }}
          onClick={() => setActive(0)}
        >
          <img
            src={companycolorsvg}
            alt=""
            srcset=""
            style={{ marginRight: "2px" }}
          />
          <Text
            size={16}
            weight={600}
            color={`${
              uselocation.pathname === "/CompanyColor" ? "#E27612" : "#051C48"
            }`}
          >
            Company Colors
          </Text>
        </Link>
        <Link
          to="/Account"
          style={{
            color: "black",
            textDecoration: "none",
            display: "flex",
            marginLeft: "29px",
          }}
          onClick={() => setActive(0)}
        >
          {" "}
          <img
            src={accountsvg}
            alt=""
            srcset=""
            style={{ marginRight: "2px" }}
          />
          <Text
            size={16}
            weight={600}
            color={`${
              uselocation.pathname === "/Account" ? "#E27612" : "#051C48"
            }`}
          >
            Account
          </Text>
        </Link>
      </div>
    </>
  );
}

export default Nav2menuitems;
