import { useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import {
  
  Button,
  Text,
  TextInput,
  PasswordInput,
  Container,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useForm, zodResolver } from "@mantine/form";
import Schema from "../ValidationLogic/Schema";
import Bg from "../Image/Bg";
import Logo from "../Logo/Logo";

export default function Register() {
  const context = useContext(UserContext);

  const signInForm = useForm({
    validateInputOnChange: true,
    validate: zodResolver(Schema),
    initialValues: {
      email: "",
      password: "",
    },
  });

  return (
    <>
      {" "}
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <div
          style={{ height: "100vh", width: "100%", backgroundColor: "white" }}
        >
          {" "}
          <Logo />
          <Container size={500} style={{ marginTop: "100px" }}>
            <form
              onSubmit={signInForm.onSubmit(async (values) => {
                await context.Login(values);
              })}
            >
              {" "}
              <Text
                // order={2}
                size={45}
                weight={500}
                align="center"
                mt="md"
                mb={10}
                color="#051C48"
              >
                Welcome back
              </Text>
              <Text
                size={20}
                color="#051C48"
                weight={400}
                align="center"
                mb={50}
              >
                Please enter your details.
              </Text>
              <TextInput
                label="User Name"
                color="#051C48"
                placeholder="enter your email"
                size="lg"
                required
                radius={7}
                {...signInForm.getInputProps("email")}
              />
              <PasswordInput
                label="Password"
                color="#051C48"
                placeholder="*****"
                mt="md"
                required
                size="lg"
                radius={7}
                {...signInForm.getInputProps("password")}
                mb={20}
              />
              <Link
                to="/ForgetPass"
                weight={700}
                style={{ textDecoration: "none" , color:"#051C48" }}
              >
                Forgot Password?
              </Link>
              <Button
                fullWidth
                mt="lg"
                size="md"
                style={{ backgroundColor: "#E27612" }}
                type="submit"
              >
                Login
              </Button>
            </form>
            <Text align="center" mt="lg" color="#051C48">
              Don’t have an account?
              <Link
                to="/NewAccount"
                weight={700}
                style={{ textDecoration: "none", marginLeft: "5px", color:"#7C93BC" }}
              >
                Contact us
              </Link>
            </Text>
          </Container>
        </div>
        <div style={{ height: "100%", width: "90%" }}>
          <Bg />
        </div>
      </div>
    </>
  );
}
