
import React from "react";
import { useState } from "react";
import useStyles from "../../../Style/UseStyle";

function Searchbar({ setSearchstring }) {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const onChange = (e) => {
    setSearch(e.target.value);
    setSearchstring(e.target.value);
    // setLoader(true);
  };

  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <div className={classes.searchnav}>
        <form className="d-flex mx-5" role="search">
   
          <input
            className="form-control me-2"
            value={search}         
            name="name"
            onChange={onChange}
            type="search"
            placeholder="Search"
            aria-label="Search"
            style={{ backgroundColor: "#EBEFF7", borderRadius: "4px", width:"100%" }}
          />
        </form>
      </div>
    </div>
  );
}

export default Searchbar;
