import React from "react";
// import { IconCategory } from "@tabler/icons";
import { Text } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import catelogue from "../../../../Images/catelogue.svg";
import category from "../../../../Images/category.svg";

function Nav2menuitemsUser() {
  const uselocation = useLocation();
  return (
    <>
      <div className=" navbar-nav d-flex">
        <Link
          to="/ArticleCard"
          style={{
            textDecoration: "none",
            display: "flex",
            marginLeft: "24px",
          }}
        >
          <img src={catelogue} alt="" srcset="" />
          <Text
            size={17}
            weight={600}
            ml={5}
            color={`${
              uselocation.pathname === "/ArticleCard" ? "#E27612" : "black"
            }`}
          >
            Catalogue
          </Text>{" "}
        </Link>
        <Link
          to="/MyLibrary"
          style={{
            color: "black",
            textDecoration: "none",
            display: "flex",
            marginLeft: "25px",
          }}
        >
          <img src={category} alt="" srcset="" />{" "}
          <Text
            size={17}
            weight={600}
            ml={5}
            color={`${
              uselocation.pathname === "/MyLibrary" ? "#E27612" : "black"
            }`}
          >
            My Library
          </Text>
        </Link>
      </div>
    </>
  );
}

export default Nav2menuitemsUser;
