import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CatelogueContext from "./CatelogueContext";

function CatelogueState(props) {
  const navigate = useNavigate();
  const [getdesignbyid, setGetdesignbyid] = useState();
  const [requestSampleData, setRequsetSampleData] = useState();

  const limit = 8;

  const getallDesignForsumPages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const json = await response.json();
      return json;
    } catch {
      navigate("/login");
    }
  };

  const getallDesign = async (num, value, string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs?limit=${limit}&offset=${num}&libraries=${value}&q=${string}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const json = await response.json();
      return json;
    } catch {
      navigate("/login");
    }
  };

  const getdesignId = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const res = await response.json();
      // console.log(res, "res");
      setGetdesignbyid(res);
      return res;
    } catch {
      navigate("/login");
    }
  };

  const addDesign = async (title, colors, patternImage, libraries) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ title, colors, patternImage, libraries }),
        }
      );

      await response.json();
    } catch {
      navigate("/login");
    }
  };

  const editDesign = async (id, title, colors, patternImage, libraries) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id, title, colors, patternImage, libraries }),
        }
      );

      await response.json();
      // console.log(upcolors);
      // for (let index = 0; index < upadtedata?.length; index++) {
      //   const element = upadtedata[index];

      //   if (element.id === id) {
      //     element.title = title;
      //     element.colors = colors;
      //     element.patternImage = patternImage;

      //   }
      // }
    } catch {
      navigate("/login");
    }
  };

  const getallUserDesigninMylibraryPage = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/my-design/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const res = await response.json();

      return res;
    } catch {
      navigate("/login");
    }
  };

  const AddRequest = async (sampleSize, designId) => {
    // API Call
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/sample-requests`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ sampleSize, designId }),
      }
    );

    const reqdata = await response.json();
    console.log(reqdata.id, "iddd");
    localStorage.setItem("requestId", reqdata.id);
    setRequsetSampleData(reqdata);
  };

  // const GetReqSample = async () => {
  //   const id = localStorage.getItem("requestId");
  //   const response = await fetch(
  //     `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/sample-requests/${id}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: ` Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   );

  //   const reqdata = await response.json();
  //   return reqdata;
  // };
  const EditRequest = async (sampleSize, designId) => {
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/sample-requests`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ sampleSize, designId }),
      }
    );

     await response.json();
    // console.log(reqdata, "req");
  };

  const DeleteDesign = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          }
        }
      );

     return await response.json();
    } catch {
      navigate("/login");
    }
  };

  return (
    <CatelogueContext.Provider
      value={{
        addDesign,
        getallDesign,
        getallDesignForsumPages,
        getdesignId,
        getdesignbyid,
        editDesign,
        getallUserDesigninMylibraryPage,
        AddRequest,
        EditRequest,
        requestSampleData,
        // GetReqSample,
        DeleteDesign
      }}
    >
      {props.children}
    </CatelogueContext.Provider>
  );
}

export default CatelogueState;
