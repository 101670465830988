import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import UserState from "./ContextFolder/UserContext/UserState";
import CompanycolorState from "./ContextFolder/CompanyColorContext/CompanycolorState";
import CatelogueState from "./ContextFolder/CatelogueContext/CatelogueState";
import LibraryState from "./ContextFolder/LibraryContext/LibraryState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   
    <BrowserRouter>
      <UserState>
        <CatelogueState>
          <LibraryState>
            <CompanycolorState>
              <App />
            </CompanycolorState>
          </LibraryState>
        </CatelogueState>
      </UserState>
    </BrowserRouter>
  </React.StrictMode>
);
