import { Button, Text } from "@mantine/core";
import { IconChevronLeft, IconEdit } from "@tabler/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CatelogueContext from "../../ContextFolder/CatelogueContext/CatelogueContext";
import { Carousel } from "@mantine/carousel";
// import mockdata from "../../Components/SituationImageObject/situationDetails";
import useStyles from "../../Components/Style/UseStyle";
import aircraft from "../../Images/PreviewImage/aircraft.png";
import livingroom from "../../Images/PreviewImage/livingroom.png";
import staircase from "../../Images/PreviewImage/staircase.png";
import checkedicon from "../../Images/checkedicon.svg";
import "../../App.css";
import * as svg from "../../RoleWisePagesAndComponents/Admins_Category/Designs/svg";
import CompanycolorContext from "../../ContextFolder/CompanyColorContext/CompanycolorContext";

function MyLibraryDesignViewEditButton() {
  const { classes, cx } = useStyles();
  const [svgString, setSvgString] = useState("");
  const [activeforcheckbox1, setActiveforcheckbox1] = useState(false);
  const [activeforcheckbox2, setActiveforcheckbox2] = useState(false);
  const [activeforcheckbox3, setActiveforcheckbox3] = useState(false);
  const context = useContext(CatelogueContext);
  const [value, setValue] = useState([]);
  const [colordata, setColordata] = useState([]);

  const navigate = useNavigate();
  const colors = svg.getColors(svgString);
  const contextcolor = useContext(CompanycolorContext);

  useEffect(() => {
    if (context?.getdesignbyid?.patternImage) {
      const fetchSVGString = async () => {
        setSvgString(`${context?.getdesignbyid?.patternImage}`);
      };
      fetchSVGString();
    }

    // eslint-disable-next-line
  }, [context?.getdesignbyid?.patternImage]);

  const designId = context?.getdesignbyid?.id;

  const handleClickForReqSample = async () => {
    await context.AddRequest(value, designId);
    navigate("/ThankYoupage");
  };

  const blob = new Blob([svgString], {
    type: "image/svg+xml",
  });

  const url = URL.createObjectURL(blob);

  useEffect(() => {
    const getcolors = async () => {
      const companycolor = await contextcolor?.getallcolorsdataForsomePages();
      setColordata(companycolor.companyColors);
    };
    getcolors();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "23%",
              width: "23%",
              // minWidth: "350px",
              height: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderBlockStart: "0.1px solid #EBEFF7",

                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {" "}
                <Button
                  variant="outline"
                  style={{ borderRadius: "4px", borderColor: "#A5BBE2" }}
                  onClick={() => {
                    navigate("/MyLibrary");
                  }}
                >
                  {/* <IconArrowLeft color="black" /> */}
                  <IconChevronLeft
                    color="#A5BBE2"
                    style={{ marginLeft: "-7px" }}
                  />
                  <Text color="#051C48">Back</Text>
                </Button>
                <Link
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    color: "black",
                  }}
                  to={`/mylibraryedit/${context?.getdesignbyid?.id}`}
                >
                  <Button
                    style={{ backgroundColor: "#E27612", borderRadius: "4px" }}
                  >
                    <IconEdit />
                    <Text color="white" ml={5}>
                      Edit
                    </Text>
                  </Button>
                </Link>
              </div>

              <div>
                <Text size="xl" weight={800} color="#051C48">
                  {context?.getdesignbyid?.title}
                </Text>
                <div
                  style={{
                    /* justify-content: center; */
                    /* display: flex; */
                    display: "block",
                    transform: "scale(0.7)translate(-30%, 0px)",
                    textAlign: "center",
                    margin: "0px auto",
                    width: "200px",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      height: "320px",
                      width: "320px",
                      marginBottom: "1px",
                      background: `url(${url})`,
                    }}
                  ></div>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    color: "#051C48",
                  }}
                >
                  Preview (1 m x 1 m)
                </p>
              </div>

              <div className="p-2">
                <div style={{ display: "flex" }}>
                  {colors.map((color) => {
                    const targetColorCode = color;
                    const editData = colordata.find(
                      (rawcolor) =>
                        rawcolor.colorCode.toLowerCase() ===
                        targetColorCode.toLowerCase()
                    );
                    const finalData = editData ? editData.name : "";

                    return (
                      <div
                        key={color}
                        style={{
                          marginRight: "10px",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                          }}
                        >
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              cursor: "pointer",
                              backgroundColor: color,
                            }}
                          ></div>
                        </div>

                        <div
                          style={{
                            marginTop: "2px",
                            wordBreak: "break-word",
                            width: "70px",
                          }}
                        >
                          <Text size={"sm"}>{finalData}</Text>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {localStorage.getItem("role") === "SuperAdmin" ? (
                <div></div>
              ) : (
                <>
                  <div style={{ marginTop: "20%" }}>
                    <Text weight={600} ml={27}>
                      Available Sample Size{" "}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "15px",
                        marginBottom: "13px",
                      }}
                    >
                      {" "}
                      <div
                        className={cx(classes.checkboxdiv, {
                          [classes.checkboxactive]: activeforcheckbox1 === true,
                        })}
                      >
                        <div class="custom-radios">
                          <div>
                            <input
                              type="radio"
                              id="color-1"
                              name="color"
                              value="FEET_1x2"
                              onClick={(e) => {
                                console.log(e.target.value);
                                setValue(e.target.value);
                                setActiveforcheckbox1(true);
                                setActiveforcheckbox2(false);
                                setActiveforcheckbox3(false);
                              }}
                            />
                            <label for="color-1">
                              <span>
                                <img src={checkedicon} alt="Checked Icon" />
                              </span>
                            </label>
                            <br /> 1X2 <br /> FEET
                          </div>
                        </div>
                      </div>
                      <div
                        className={cx(classes.checkboxdiv, {
                          [classes.checkboxactive]: activeforcheckbox2 === true,
                        })}
                      >
                        <div class="custom-radios">
                          <div>
                            <input
                              type="radio"
                              id="color-2"
                              name="color"
                              value="FEET_2x3"
                              onClick={(e) => {
                                console.log(e.target.value);
                                setValue(e.target.value);
                                setActiveforcheckbox2(true);
                                setActiveforcheckbox1(false);
                                setActiveforcheckbox3(false);
                              }}
                            />
                            <label for="color-2">
                              <span>
                                <img src={checkedicon} alt="Checked Icon" />
                              </span>
                            </label>
                            <br /> 2X3 <br /> FEET
                          </div>
                        </div>
                      </div>
                      <div
                        className={cx(classes.checkboxdiv, {
                          [classes.checkboxactive]: activeforcheckbox3 === true,
                        })}
                      >
                        <div class="custom-radios">
                          <div>
                            <input
                              type="radio"
                              id="color-3"
                              name="color"
                              value="FEET_3x4"
                              onClick={(e) => {
                                setValue(e.target.value);
                                setActiveforcheckbox3(true);
                                setActiveforcheckbox2(false);
                                setActiveforcheckbox1(false);
                              }}
                            />
                            <label for="color-3">
                              <span>
                                <img src={checkedicon} alt="Checked Icon" />
                              </span>
                            </label>
                            <br /> 3X4 <br /> FEET
                          </div>
                        </div>
                      </div>
                    </div>

                    <Button
                      onClick={() => {
                        handleClickForReqSample();
                      }}
                      disabled={value.length === 0}
                      style={{
                        backgroundColor: "#E27612",
                        borderRadius: "4px",
                        width: "88%",
                        marginLeft: "18px",
                      }}
                    >
                      <Text color="white" ml={5}>
                        Request Sample
                      </Text>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              width: "77%",
              maxWidth: "77%",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#F5F7FB",
              }}
            >
              <Carousel withIndicators>
                <Carousel.Slide mt={30}>
                  <div class="situation-box1">
                    <div class="situation1">
                      <div class="situation-pattern-container1">
                        <div
                          class="situation-pattern1"
                          style={{ backgroundImage: `url(${url})` }}
                        ></div>
                      </div>
                    </div>

                    <div class="situation-image-box">
                      <img
                        src={aircraft}
                        class="situation-image"
                        alt="not found"
                      />
                    </div>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide mt={60}>
                  <div class="situation-box2">
                    <div class="situation2">
                      <div class="situation-pattern-container2">
                        <div
                          class="situation-pattern2"
                          style={{ backgroundImage: `url(${url})` }}
                        ></div>
                      </div>
                    </div>
                    <div class="situation-image-box">
                      <img
                        src={livingroom}
                        class="situation-image"
                        alt="not found"
                      />
                    </div>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide mt={60}>
                  <div class="situation-box2">
                    <div class="situation3">
                      <div class="situation-pattern-container3">
                        <div
                          class="situation-pattern3"
                          style={{ backgroundImage: `url(${url})` }}
                        ></div>
                      </div>
                    </div>
                    <div class="situation-image-box">
                      <img
                        src={staircase}
                        class="situation-image"
                        alt="not found"
                      />
                    </div>
                  </div>
                </Carousel.Slide>

                {/* {mockdata.map((data) => (
                  <>
                    {" "}
                    <Carousel.Slide mt={data.margintop}>
                      <div
                        style={{
                          width: `${data.width}`,
                          margin: "0px auto",
                          position: "relative",
                          height: `${data.height}`,
                        }}
                      >
                        <div
                          style={{
                            width: `${data.width}`,
                            margin: "0px auto",
                            position: "relative",
                            height: `${data.height}`,
                            overflow: "hidden",
                            perspective: `${data.perspective}`,
                            zIndex: "10",
                          }}
                        >
                          <div
                            style={{
                              transform: `${data.transform}`,
                              zIndex: "10",
                              position: "absolute",
                            }}
                          >
                            <div
                              style={{
                                background: `url(${url})`,
                                height: `${data.heightforpattern}`,
                                width: `${data.widthforpattern}`,
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              zIndex: "999",
                              top: "0px",
                              left: "0px",
                            }}
                          >
                            {" "}
                            <img
                              src={data.image1}
                              alt="not found"
                              style={{
                                position: "absolute",
                                zIndex: "999",
                                top: "0px",
                                left: "0px",
                                transform:
                                  "scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(100px) skewX(0deg) skewY(0deg)",
                              }}
                            />{" "}
                          </div>
                        </div>{" "}
                      </div>
                    </Carousel.Slide>
                  </>
                ))} */}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyLibraryDesignViewEditButton;
