import React from "react";
import {
  Card,
  Checkbox,
  Container,
  Menu,
  ScrollArea,
  SimpleGrid,
  Text,
  Group,
  UnstyledButton,
  Title,
  Loader,
  Divider,
  Pagination,
} from "@mantine/core";
import editicon from "../../../Images/editicon.svg";
import category from "../../../Images/category.svg";
import downarreowforlibraryfilteroption from "../../../Images/downarreowforlibraryfilteroption.svg";

import useStyles from "../../../Components/Style/UseStyle";
import texture from "../../../Images/Texture.svg";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import { IconSearch } from "@tabler/icons";
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import _ from "lodash";
import "../../../App";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import UserContext from "../../../ContextFolder/UserContext/UserContext";

function ArticleCard({ searchstring }) {
  const storedPage = parseInt(localStorage.getItem("currentPage"), 10) || 1;
  const [currentpage, setCurrentpage] = useState(storedPage);
  const [loader, setLoader] = useState(false);
  const limit = 8;

  // eslint-disable-next-line
  const [scrolled, setScrolled] = useState(false);
  const [posts, setPosts] = useState();
  const [data, setData] = useState();
  const [userlibraries, setUserlibraries] = useState();
  const [value, setValue] = useState([]);
  // const [size, setSize] = useState();
  const [number, setNumber] = useState();
  const context = useContext(CatelogueContext);
  const contextuser = useContext(UserContext);
  const contextlib = useContext(LibraryContext);

  useEffect(() => {
    const userlibrarydata = async () => {
      const userlibrariesdata = await contextuser.getprofile();
      setUserlibraries(userlibrariesdata.libraries);

      if (localStorage.getItem("role") === "SuperAdmin") {
        setLoader(true);
        const result = await context.getallDesign(
          currentpage,
          value,
          searchstring
        );
        setPosts(result.designs);
        setNumber(result.count);
        // setSize(result.designs.length);
        setLoader(false);
      } else {
        const userlibrary = userlibrariesdata?.libraries?.map(
          (sd) => `${sd.id}`
        );
        setValue(userlibrary);
        if (userlibrary.length === 0) {
          setValue([]);
          setPosts([]);
        } else {
          setLoader(true);
          const result = await context.getallDesign(
            currentpage,
            userlibrary,
            searchstring
          );

          setPosts(result.designs);
          setNumber(result.count);
          // setSize(result.designs.length);
          setLoader(false);
        }
      }
    };

    const getlibrary = async () => {
      const alllibraries = await contextlib.getlibraryforSomepage();
      setData(alllibraries);
    };

    userlibrarydata();
    getlibrary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchstring]);

  const navigate = useNavigate();
  const { classes } = useStyles();
  const pageCount = number ? Math.ceil(number / limit) : 0;
  const pages = _.range(1, pageCount + 1);

  useEffect(() => {
    localStorage.setItem("currentPage", currentpage.toString());
  }, [currentpage]);

  const setPage = async (num) => {
    const g = await context.getallDesign(num, value, searchstring);
    setPosts(g.designs);
    setCurrentpage(num);
  };

  const features = posts?.map((feature) => {
    let blob = new Blob([feature.patternImage], { type: "image/svg+xml" });
    let url = URL.createObjectURL(blob);

    return (
      <div>
        <Card key={feature.id} shadow="md" radius="md" className={classes.card}>
          <div
            style={{ cursor: "pointer" }}
            className="container"
            onClick={async () => {
              await context.getdesignId(feature.id);
              navigate("/view");
            }}
          >
            <Card.Section className={classes.imageSection}>
              {" "}
              <div
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  height: "100%",
                  backgroundImage: `url(${url})`,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    height: "100%",
                  }}
                  src={texture}
                  alt="not found"
                />
              </div>
              <div className="overlay"></div>
              <div
                style={{
                  backgroundColor: "#E27612",
                  height: "33px",
                  width: "150px",
                  borderRadius: "5px",
                }}
                className="content"
              >
                {" "}
                <div style={{ marginTop: "4px" }}>
                  {" "}
                  <IconSearch style={{ marginRight: "10px" }} />
                  View Design
                </div>
              </div>
            </Card.Section>
          </div>
          <Text style={{ color: "#051C48", fontSize: "18px" }} weight={500}>
            {feature.title}
          </Text>{" "}
          <Divider my="xs" color="#D0EBFF" />
          <div className={classes.carddisplay}>
            <div className={classes.carddisplay}>
              <div>
                {" "}
                <img src={category} alt="" srcset="" />
              </div>

              <Text
                style={{
                  color: "#051C48",
                  fontSize: "14px",
                  marginLeft: "7px",
                  marginTop: "4px",
                }}
                weight={500}
              >
                {feature?.libraries[0]?.name}
              </Text>
            </div>
            <div>
              <Link
                style={{
                  textDecoration: "none",
                  display: "flex",
                  color: "black",
                }}
                to={`/editpage/${feature.id}`}
              >
                <img src={editicon} alt="" srcset="" />
                <Text
                  size="sm"
                  mt={3}
                  ml={5}
                  mr={2}
                  color="#051C48"
                  style={{ fontSize: "12px" }}
                >
                  Edit
                </Text>
              </Link>
            </div>
          </div>
        </Card>
      </div>
    );
  });

  return (
    <div>
      <div className={classes.backgroundColor} style={{ height: "100%" }}>
        <Container fluid py="xl" style={{ padding: "22px" }}>
          <div className={classes.navtype}>
            <Text className={classes.catalogue}>Catalogue</Text>

            {currentpage === 1 ? (
              <div>
                <Menu
                  width={300}
                  position="bottom-end"
                  transition="pop-top-right"
                  className={classes.menu}
                >
                  <Menu.Target>
                    <UnstyledButton
                      style={{
                        borderColor: "#A5BBE2",
                        border: "2px solid white",
                      }}
                    >
                      <Group spacing={100}>
                        <Text
                          weight={500}
                          size="md"
                          sx={{ lineHeight: 1 }}
                          mr={30}
                          color="#A5BBE2"
                        >
                          Library
                        </Text>
                        <img
                          src={downarreowforlibraryfilteroption}
                          alt=""
                          srcset=""
                        />
                        {/* <IconCircleCaretDown
                          size={15}
                          stroke={4}
                          color="#A5BBE2"
                        /> */}
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>

                  {localStorage.getItem("role") === "SuperAdmin" ? (
                    <Menu.Dropdown>
                      <ScrollArea
                        sx={{ height: 150 }}
                        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                      >
                        {" "}
                        <Menu.Item closeMenuOnClick={false}>
                          <Checkbox.Group
                            value={value}
                            onChange={async (value) => {
                              setValue(value);
                              setLoader(true);
                              const result = await context.getallDesign(
                                currentpage,
                                value,
                                searchstring
                              );
                              setPosts(result.designs);
                              setNumber(result.count);
                              // setSize(result.designs.length);
                              setLoader(false);
                            }}
                            withAsterisk
                          >
                            {" "}
                            <div>
                              {data?.count === 0 ? (
                                <div>Please Enter new library</div>
                              ) : (
                                data?.libraries?.map((row) => (
                                  <Checkbox
                                    key={row.id}
                                    value={row.id}
                                    label={row.name}
                                    mt={3}
                                    color="orange"
                                  />
                                ))
                              )}
                            </div>
                          </Checkbox.Group>
                        </Menu.Item>{" "}
                      </ScrollArea>
                    </Menu.Dropdown>
                  ) : (
                    <Checkbox.Group
                      value={value}
                      onChange={async (value) => {
                        if (value.length === 0) {
                          setLoader(true);
                          setValue([]);
                          setPosts([]);

                          setLoader(false);
                        } else {
                          setValue(value);
                          setLoader(true);

                          const result = await context.getallDesign(
                            currentpage,
                            value,
                            searchstring
                          );
                          setPosts(result.designs);
                          setNumber(result.count);
                          // setSize(result.designs.length);
                          setLoader(false);
                        }
                      }}
                      withAsterisk
                    >
                      <Menu.Dropdown>
                        {userlibraries?.map((row) => (
                          <Checkbox
                            key={row.id}
                            value={row.id}
                            mt={6}
                            label={row.name}
                            color="orange"
                          />
                        ))}
                      </Menu.Dropdown>
                    </Checkbox.Group>
                  )}
                </Menu>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div style={{ display: "grid", minHeight: "90vh" }}>
            {loader === true ? (
              <Container py="xl" mt={100}>
                {" "}
                <Loader color="orange" size="xl" variant="dots" />
              </Container>
            ) : (
              <div>
                {features?.length === 0 ? (
                  <Title size={30} weight={400} align="center" mt={100}>
                    No data
                  </Title>
                ) : (
                  <SimpleGrid
                    cols={4}
                    spacing="lg"
                    mt={20}
                    breakpoints={[
                      { maxWidth: "md", cols: 2 },
                      { maxWidth: "sm", cols: 1 },
                    ]}
                  >
                    {features}
                  </SimpleGrid>
                )}
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "40px",
              marginBottom: "10px",
            }}
          >
            {features?.length === 0 ? (
              ""
            ) : (
              <>
                <Pagination
                  page={currentpage}
                  onChange={setPage}
                  total={pages.length}
                  withEdges
                />
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ArticleCard;
