import { Button, Container, Text, TextInput } from "@mantine/core";
import React, { useContext, useEffect } from "react";
import CompanycolorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import useStyles from "../../../../Components/Style/UseStyle";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";

function Addcolor() {
  const context = useContext(CompanycolorContext);
  const { id } = useParams();
  const { updatedcolor } = context;
  const navigate = useNavigate();
  const form = useForm({ initialValues: { name: "", colorCode: "" } });
  useEffect(() => {
    const getcolors = async () => {
      const companycolor = await context.getcolorbyid(id);
      form.setValues({
        name: companycolor?.name,
        colorCode: companycolor?.colorCode,
      });
    };

    getcolors();
    // eslint-disable-next-line
  }, []);

  const { classes } = useStyles();
  return (
    <div className={classes.background}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Edit Color
          </Text>

          <Container mt={5}>
            <form
              onSubmit={form.onSubmit(async (values) => {
                if (!values.name || !values.colorCode) {
                  alert("please enter Details");
                } else {
                  await updatedcolor(id, values.name, values.colorCode);
                  navigate("/CompanyColor");
                }
              })}
            >
              <div
                style={{
                  height: "140px",
                  backgroundColor: ` ${form.values.colorCode}`,
                  color: `${form.values.colorCode}`,
                  marginBottom: "20px",
                  borderRadius:"6px",
                  border: "2px solid #7C93BC ",
                }}
              ></div>
              <TextInput
                placeholder="#496738"
                label="Color code"
                color="#051C48"
                id="colorCode"
                name="colorCode"
                required
                {...form.getInputProps("colorCode")}
                sx={{ flex: 1 }}
                mb={20}
              />
              <TextInput
                mt="md"
                {...form.getInputProps("name")}
                label="Name"
                color="#051C48"
                id="name"
                name="name"
                required
                placeholder="Artichoke"
                sx={{ flex: 1 }}
                mb={10}
              />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                  mt="md"
                  variant="outline"
                  onClick={() => {
                    navigate("/CompanyColor");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  mt="md"
                  style={{ backgroundColor: "#E27612", padding:"12px" }}
                >
                  Save
                </Button>

             
              </div>
            </form>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default Addcolor;
