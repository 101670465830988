import { Container, Text, useMantineTheme } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../Components/Style/UseStyle";
import { useForm, zodResolver } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Paper,
  Group,
  Button,
  Divider,
  Stack,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconCloudUpload, IconDownload, IconX } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import resetFormSchema from "../../Components/lockscreen/ValidationLogic/resetFormSchema";
import AddAccountSchema from "../../Components/lockscreen/ValidationLogic/AddAccountSchema";
import { useContext } from "react";
import UserContext from "../../ContextFolder/UserContext/UserContext";
function Useraccount() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const [filesource, setFileSource] = useState();
  const [id, setId] = useState();
  const [data, setdata] = useState();
  const [active, setActive] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setId(userId);
    const userdata = async () => {
      const userdata = await context.getprofile();
      useracc.setValues({
        name: userdata?.fullName,
        email: userdata?.email,
      });
    };
    userdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useracc = useForm({
    validateInputOnChange: true,
    validate: zodResolver(AddAccountSchema),
    initialValues: {
      email: "",
      name: "",
    },
  });
  const resetpass = useForm({
    validateInputOnChange: true,
    validate: zodResolver(resetFormSchema),
    initialValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const resetPassword = async (password) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/user-profile`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ password }),
        }
      );
      await response.json();
    } catch {
      navigate("/login");
    }
  };

  const addlogo = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/avatar/${id}`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );
      await response.json();
    } catch {
      navigate("/login");
    }
  };

  const { classes, cx } = useStyles();
  const openRef = useRef(null);
  // eslint-disable-next-line
  const [image, setImages] = useState();
  // console.log(image);
  return (
    <div className={classes.backgroundColor}>
      <Container size="xl" py="xl">
        <div className={classes.navtype} style={{ marginBottom: "20px" }}>
          <Text className={classes.catalogue}>My Account</Text>
        </div>
        <Container size="xl" py="xl" style={{ backgroundColor: "white" }}>
          <div style={{ display: "flex", width: "100%" }}>
            {" "}
            <div style={{ width: "50%" }}>
              <Paper radius="md" p="xl">
                <Text size="xl" weight={500}>
                  Account Details
                </Text>

                <form encType="multipart/form-data">
                  <Stack>
                    <TextInput
                      label="Name"
                      {...useracc.getInputProps("name")}
                      readOnly
                    />

                    <TextInput
                      label="Email"
                      {...useracc.getInputProps("email")}
                      readOnly
                    />

                    <Text weight={600} size={14}>
                      Upload Logo
                    </Text>
                    {!filesource ? (
                      <Dropzone
                        // {...useracc.getInputProps("img")}
                        maxSize={15000}
                        openRef={openRef}
                        onReject={(files) => {
                          // console.log("rejected files", files);
                          setActive(true);
                        
                        }}
                        onDrop={(acceptedFiles) => {
                          setImages(
                            // eslint-disable-next-line
                            acceptedFiles.map((file) => {
                              setdata(file); //API call
                              setFileSource(URL.createObjectURL(file));
                            })
                          );
                        }}
                        style={{ height: "100%", width: "100%" }}
                        className={cx({
                          [classes.dropzoneActive]: active ,
                        })}
                        radius="md"
                        accept={[
                          MIME_TYPES.png,
                          MIME_TYPES.jpeg,
                          MIME_TYPES.gif,
                          MIME_TYPES.webp,

                        ]}
                      >
                        <div style={{ pointerEvents: "none" }}>
                          <Group position="center">
                            <Dropzone.Reject>
                              <IconX
                                size={50}
                                stroke={1.5}
                                color={
                                  theme.colors.red[
                                    theme.colorScheme === "dark" ? 4 : 6
                                  ]
                                }
                              />
                            </Dropzone.Reject>
                            <Dropzone.Accept>
                              <IconDownload
                                color="orange"
                                size={50}
                                stroke={1.5}
                              />
                            </Dropzone.Accept>

                            <Dropzone.Idle>
                              <IconCloudUpload
                                color="orange"
                                size={50}
                                stroke={1.5}
                              />
                            </Dropzone.Idle>
                          </Group>

                          <Text align="center" weight={700} size="lg" mt="xl">
                            <Dropzone.Accept>Drop files here</Dropzone.Accept>
                            <Dropzone.Reject></Dropzone.Reject>
                            <Dropzone.Idle>
                              Click here to upload or drag and drop
                            </Dropzone.Idle>
                          </Text>
                          <Text align="center" size="sm" mt="xs" color="dimmed">
                            Supports: PNG, Maximum size: 150px width and
                            50px height
                          </Text>
                        </div>
                      </Dropzone>
                    ) : (
                      <img
                        src={filesource}
                        alt="not found"
                        height="150px"
                        width="100%"
                      />
                    )}
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      mt="md"
                      variant="outline"
                      style={{padding:"10px"}}
                      onClick={() => {
                        navigate("/ArticleCard");
                      }}
                    >
                      Back
                    </Button>

                    <Button
                      mt="md"
                      style={{ backgroundColor: "#E27612" , padding:"10px"}}
                      onClick={async () => {
                        await addlogo(data);
                        window.location.reload();
                      }}
                    >
                      Save
                    </Button>
                  </div>{" "}
                </form>
              </Paper>
            </div>{" "}
            <Divider ml={30} mr={30} size="md" orientation="vertical" />
            <div style={{ width: "50%" }}>
              {" "}
              <Paper className={classes.form} radius={0} p={30}>
                <form
                  onSubmit={resetpass.onSubmit(async (values) => {
                    await resetPassword(values.confirmPassword);
                    alert("password Updated");
                    resetpass.setValues({ password: "", confirmPassword: "" });
                  })}
                >
                  {" "}
                  <Text size="xl" weight={500} mb={20}>
                    Password
                  </Text>
                  <PasswordInput
                    label="New Password"
                    placeholder="********"
                    mt="md"
                    size="md"
                    {...resetpass.getInputProps("password")}
                  />
                  <PasswordInput
                    label="Confirm Password"
                    placeholder="********"
                    mt="md"
                    size="md"
                    {...resetpass.getInputProps("confirmPassword")}
                  />{" "}
                  <Button
                    mt="xl"
                    size="sm"
                    style={{ backgroundColor: "#E27612" }}
                    type="submit"
                  >
                    Update Password
                  </Button>
                </form>
              </Paper>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
}

export default Useraccount;
