import { Container, Flex, Button, Text, Group } from "@mantine/core";
import { useRef } from "react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons";
import React from "react";
import useStyles from "../../../Components/Style/UseStyle";
import { Link, useNavigate } from "react-router-dom";

function UploadFile({ setFileSource }) {
  const navigate = useNavigate();
  const [images, setImages] = React.useState([]);
  const { classes, theme } = useStyles();
  const openRef = useRef();

  console.log(images);

  return (
    <>
      <div className={classes.backgroundColor}>
        <Container className={classes.containersize} py="xl">
          <div className={classes.uploadfile}>
            <div>
              <Dropzone
                openRef={openRef}
                onDrop={(acceptedFiles) => {
                  setImages(
                    // eslint-disable-next-line
                    acceptedFiles.map((file) => {
                      setFileSource(URL.createObjectURL(file));
                      navigate("/DesignUploaded");
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      });
                    })
                  );
                }}
                radius="md"
                accept={[MIME_TYPES.svg]}
                maxSize={30 * 1024 ** 2}
              >
                <div style={{ pointerEvents: "none" }}>
                  <Group position="center">
                    <Dropzone.Accept>
                      <IconDownload
                        size={50}
                        color={theme.colors[theme.primaryColor][6]}
                        stroke={1.5}
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size={50}
                        color={theme.colors.red[6]}
                        stroke={1.5}
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconCloudUpload size={70} color="#E27612" stroke={2} />
                    </Dropzone.Idle>
                  </Group>

                  <Text align="center" weight={500} size={25} mt="xl">
                    <Dropzone.Reject></Dropzone.Reject>
                    <Dropzone.Idle>
                      <text style={{ color: "#E27612" }}> Click here</text> to
                      upload or drag and drop
                    </Dropzone.Idle>
                  </Text>
                  <Text align="center" size={12} mt="xs" color="dimmed">
                    Supports: SVG Image
                  </Text>
                  <Text align="center" size={18} color="#E27612" mt={50}>
                    Upload an SVG vector file for an accurate result.
                  </Text>
                </div>
              </Dropzone>
            </div>
            <Flex
              mih={70}
              gap="md"
              justify="center"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Link style={{ textDecoration: "none" }} to="/ArticleCard">
                <Button
                  style={{ width: 140, color: "#051C48" }}
                  fullWidth
                  variant="default"
                >
                  Cancel
                </Button>
              </Link>
            </Flex>
          </div>
          {/* <img src={images[0].preview} alt="ghvj"/> */}
        </Container>
      </div>
    </>
  );
}

export default UploadFile;
