import { Button, Container, Text, TextInput } from "@mantine/core";
import React, { useContext, useState } from "react";
import CompanycolorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import useStyles from "../../../../Components/Style/UseStyle";
import { Link, useNavigate } from "react-router-dom";
function Addcolor() {
  const context = useContext(CompanycolorContext);
  const navigate = useNavigate();
  const { addcolor } = context;
  const [color, setColor] = useState({
    name: "",
    colorCode: "",
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (!color.name || !color.colorCode) {
      alert("please enter Details");
    } else {
      addcolor(color.name, color.colorCode);
      setColor({
        name: "",
        colorCode: "",
      });
      navigate("/CompanyColor");
    }
  };
  const handleChange = (e) => {
    setColor({ ...color, [e.target.name]: e.target.value });
  };

  const { classes } = useStyles();
  return (
    <div className={classes.background}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Add Color
          </Text>

          <Container mt={5}>
            <form>
              <div
                style={{
                  // width: "250px",
                  height: "140px",
                  backgroundColor: `${color.colorCode}`,
                  color: `${color.colorCode}`,
                  marginBottom: "20px",
                  borderRadius:"6px",
                  border: "2px solid #7C93BC ",
                  // alignItems:"center"
                }}
              ></div>
              <TextInput
                placeholder="#496738"
                label="Color code"
                id="colorCode"
                color="#051C48"
                name="colorCode"
                required
                value={color.colorCode}
                sx={{ flex: 1 }}
                onChange={handleChange}
                mb={10}
              />
              <TextInput
                mt="lg"
                onChange={handleChange}
                color="#051C48"
                label="Name"
                id="name"
                value={color.name}
                name="name"
                required
                placeholder="Artichoke"
                sx={{ flex: 1 }}
                mb={10}
              />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link
                  style={{ textDecoration: "none", display: "flex" }}
                  to="/CompanyColor"
                >
                  <Button mt="md" variant="outline">
                  Cancel
                  </Button>
                </Link>
                <Button
              
                  type="submit"
                  onClick={handleClick}
                  mt="md"
                  style={{backgroundColor:"#E27612", padding:"12px"}}
                >
                  Save
                </Button>
                
              </div>
            </form>
          </Container>
        </div>
      </Container>
    </div>
    // <div>
    //       <input
    //           type="name"
    //           className="form-control"
    //           id="name"
    //           value={color.name}
    //           name="name"
    //           onChange={handleChange}
    //         /><br/>
    //           <input
    //           type="name"
    //           className="form-control"
    //           id="colorCode"
    //           value={color.colorCode}
    //           name="colorCode"
    //           onChange={handleChange}
    //         />
    //          <button
    //           onClick={handleClick}
    //           type="button"
    //           className="btn btn-dark"
    //           data-bs-toggle="button"

    //         >
    //           Add color
    //         </button>
    // </div>
  );
}

export default Addcolor;
