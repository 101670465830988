import React from "react";
import { useMediaQuery } from "@mantine/hooks";
import Searchbar from "./smallcomponents/Searchbar";
import Sideitem from "./smallcomponents/Sideitem";
import Nav2 from "../Navbar2/Nav2";
import AdminLogomobile from "./formobileview/AdminResponsive";
import { Burger } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import logo from "../../../Images/logo.png";
import { useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";

function Nav1({ setSearchstring, searchstring }) {
  const isTabletOrDesktop = useMediaQuery("(min-width: 700px)");
  const navigate = useNavigate();

  const uselocation = useLocation();
  const [first, setfirst] = useState("");
  const [userData, setUserData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    const data = async () => {
      const userdata = await context.getmyprofile();
      setUserData(userdata);
      if (userdata.avatarId) {
        const response = await fetch(
          `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/database-files/${userdata.avatarId}`,
          {
            method: "GET",
            headers: {
              Authorization: ` Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const all = await response;

        setfirst(all.url);
        return all;
      } else {
        console.log("logo not found");
      }
    };
    data();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isTabletOrDesktop ? (
        <>
          {" "}
          <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
              <div className="navbar-brand">
                {userData && !userData.avatarId && (
                  <img
                    onClick={() => {
                      navigate("/ArticleCard");
                    }}
                    src={logo}
                    style={{
                      width: "120px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    alt="not found"
                  />
                )}
                {userData && userData.avatarId && first && (
                  <img
                    src={first}
                    style={{
                      width: "150px",
                      height: "50px",
                      marginBottom: "5px",
                      marginLeft: "20px",
                      marginRight: "13px",
                    }}
                    alt=" logo is not available"
                  />
                )}
              </div>
              {uselocation.pathname === "/ArticleCard" ? (
                <Searchbar
                  setSearchstring={setSearchstring}
                  searchstring={searchstring}
                />
              ) : null}

              <Sideitem />
            </div>
          </nav>
          {uselocation.pathname === "/ArticleCard" ? <Nav2 /> : null}
          {uselocation.pathname === "/CompanyColor" ? <Nav2 /> : null}
          {uselocation.pathname === "/Libraries" ? <Nav2 /> : null}
          {uselocation.pathname === "/Account" ? <Nav2 /> : null}
          {uselocation.pathname === "/MyLibrary" ? <Nav2 /> : null}
        </>
      ) : (
        <>
          <div className=" d-flex justify-content-between">
            <Burger
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
            ></Burger>
            <a className="navbar-brand" href="/ArticleCard">
              {userData && !userData.avatarId && (
                <img
                  src={logo}
                  style={{ width: "120px", marginBottom: "10px" }}
                  alt="rgre"
                />
              )}
              {userData && userData.avatarId && first && (
                <img
                  src={first}
                  style={{
                    width: "120px",
                    marginBottom: "10px",
                  }}
                  alt=" logo is not available"
                />
              )}
            </a>
            {uselocation.pathname === "/ArticleCard" ? (
              <Searchbar
                setSearchstring={setSearchstring}
                searchstring={searchstring}
              />
            ) : null}
            <AdminLogomobile />
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            {uselocation.pathname === "/ArticleCard" ? <Nav2 /> : null}
            {uselocation.pathname === "/CompanyColor" ? <Nav2 /> : null}
            {uselocation.pathname === "/Libraries" ? <Nav2 /> : null}
            {uselocation.pathname === "/Account" ? <Nav2 /> : null}
            {uselocation.pathname === "/MyLibrary" ? <Nav2 /> : null}
          </div>
        </>
      )}
    </>
  );
}

export default Nav1;
