import React from "react";
import { Navigate } from "react-router-dom";
import Account from "../../../RoleWisePagesAndComponents/Admins_Category/Accountss/Account";
import Editacc from "../../../RoleWisePagesAndComponents/Admins_Category/Accountss/Editacc";
import  ArticleCard  from "../../../RoleWisePagesAndComponents/Admins_Category/Catelogue/ArticleCard";
import CompanyColor from "../../../RoleWisePagesAndComponents/Admins_Category/CompanyColor/CompanyColor";
import ChangeColor from "../../../RoleWisePagesAndComponents/Admins_Category/Designs/ChangeColor";
import DesignUploaded from "../../../RoleWisePagesAndComponents/Admins_Category/Designs/uploadedDesign/DesignUploaded";
import UploadFile from "../../../RoleWisePagesAndComponents/Admins_Category/Designs/UploadFile";
import EditLib from "../../../RoleWisePagesAndComponents/Admins_Category/Libraries/EditLib";
import Libraries from "../../../RoleWisePagesAndComponents/Admins_Category/Libraries/Libraries";
import CreateNewPass from "../../lockscreen/AllformFormat/CreateNewPass";
import ForgetPass from "../../lockscreen/AllformFormat/ForgetPass";
import ForgetPassSub from "../../lockscreen/AllformFormat/ForgorPassSub";
import Register from "../../lockscreen/AllformFormat/Login";
import NewAccount from "../../lockscreen/AllformFormat/NewAccount";
import User from "../../../RoleWisePagesAndComponents/User-Category/User";
import Addcolor from "../../../RoleWisePagesAndComponents/Admins_Category/CompanyColor/Colors/Addcolor";
import Updatecolor from "../../../RoleWisePagesAndComponents/Admins_Category/CompanyColor/Colors/Updatecolor";
import MyLibraryuser from "../../../RoleWisePagesAndComponents/User-Category/MyLibraryuser";

import Useraccount from "../../../RoleWisePagesAndComponents/User-Category/Useraccount";

import Thankyoupage from "../../../RoleWisePagesAndComponents/User-Category/Thankyoupage";
import DesignUploaded2 from "../../../RoleWisePagesAndComponents/Admins_Category/Designs/uploadedDesign/DesignUploaded2";
import Designview from "../../../RoleWisePagesAndComponents/Admins_Category/Designs/Designview";
import SavenewChanges from "../../../RoleWisePagesAndComponents/Admins_Category/Catelogue/SavenewChanges";
import AddLib from "../../../RoleWisePagesAndComponents/Admins_Category/Libraries/AddLib";
import ResetPass from "../../../RoleWisePagesAndComponents/Admins_Category/Accountss/ResetPass";
import AddAcc from "../../../RoleWisePagesAndComponents/Admins_Category/Accountss/AddAcc";
// import viewAccessableDesign from "../Admins_Category/Catelogue/viewAccessableDesign";
import Designaccess from "../../../RoleWisePagesAndComponents/Admins_Category/Catelogue/viewAccessableDesign";
import EditPageByUser from "../../../RoleWisePagesAndComponents/User-Category/EditPageByUser";
import MyLibraryDesignViewEditButton from "../../../RoleWisePagesAndComponents/User-Category/MyLibraryDesignViewEditButton";


const publicRoutes = [
  { path: "/ForgetPass", component: ForgetPass },
  { path: "/ForgetPassSub", component: ForgetPassSub },
  { path: "/NewAccount", component: NewAccount },
  { path: "/CreateNewPass", component: CreateNewPass },
  { path: "/login", component: Register },
  { path: "/", exact: true, component: () => <Navigate to="/login" /> },
  { path: "*", component: () => <Register /> },
];

const protectedRoutesForAdmin = [
  { path: "/ArticleCard", component: ArticleCard },
  { path: "/CompanyColor", component: CompanyColor },
  { path: "/Account", component: Account },
  { path: "/Libraries", component: Libraries },
  { path: "/Editacc/:id", component: Editacc },
  { path: "/EditLib/:id", component: EditLib },
  { path: "/addlibrary", component: AddLib },
  { path: "/UploadFile", component: UploadFile },
  { path: "/DesignUploaded", component: DesignUploaded },
  { path: "/ChangeColor", component: ChangeColor },
  { path: "/resetpassword/:id", component: ResetPass },
  { path: "/Addcolor", component: Addcolor },
  { path: "/addaccount", component: AddAcc },
  { path: "/Updatecolor/:id", component: Updatecolor },
  { path: "/Design", component: DesignUploaded2 },
  { path: "/view", component: Designview },
  { path: "/editpage/:id", component: SavenewChanges },
  { path: "/viewdesign/:id", component: Designaccess },

];

const protectedRoutesForUser = [
  { path: "/User", component: User },
  { path: "/MyLibrary", component: MyLibraryuser },
  { path: "/view", component: Designview },
  { path: "/ArticleCard", component: ArticleCard },
  { path: "/Useraccount", component: Useraccount },
  { path: "/ThankYoupage", component: Thankyoupage },
  { path: "/editpage/:id", component: SavenewChanges },
  { path: "/mylibraryedit/:id", component: EditPageByUser },
  { path: "/mylibrarydesignedit", component: MyLibraryDesignViewEditButton },

  
  
];

export { protectedRoutesForAdmin, publicRoutes, protectedRoutesForUser };
