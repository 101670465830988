import { Button, Text, PasswordInput, Container } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Bg from "../Image/Bg";
import Logo from "../Logo/Logo";
import resetFormSchema from "../ValidationLogic/resetFormSchema";
import { useSearchParams } from "react-router-dom";
export default function CreateNewPass() {
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(resetFormSchema),
    initialValues: {
      password: "",
      confirmPassword: "",
      status: false,
    },
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    const varification = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );
        const res = await response.json();
        if (!res.status) {
          console.log("user not found");
          alert("User not Found");
        }
      } catch {
        alert("Not Found");
        // navigate("/login");
      }
    };

    varification();
    // resetpassword();
    // eslint-disable-next-line
  }, []);

  const resetpassword = async (password, resetPasswordToken) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, resetPasswordToken }),
        }
      );
      await response.json();
      // // console.log("dd",loginResponse)
      // if (!res.status) {
      //   console.log("user not found");
      //   alert("User not Found");
      // }
    } catch {
      alert("Something Wrong!!!");
      // navigate("/login");
    }
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <div
          style={{ height: "100vh", width: "100%", backgroundColor: "white" }}
        >
          {" "}
          <Logo />
          <Container size={500} style={{ marginTop: "100px" }}>
            <Text
              order={2}
              size={40}
              weight={500}
              align="center"
              mt="md"
              mb={10}
              color="#051C48"
            >
              Create New Password
            </Text>
            <Text size={20} color="#051C48" weight={400} align="center" mb={50}>
              Your new password must be different <br />
              from previous used passwords.
            </Text>
            <form
              onSubmit={form.onSubmit(async (values) => {
                console.log("values", values);
                await resetpassword(values.confirmPassword, token);
                navigate("/login");
                // await context.Login(values);
                // await context.getmyprofile(values);
              })}
            >
              <PasswordInput
                label="New Password"
                placeholder="*****"
                required
                size="lg"
                mt="md"
                radius={7}
                {...form.getInputProps("password")}
              />
              <PasswordInput
                label="Confirm Password"
                placeholder="*****"
                required
                mt="md"
                size="lg"
                {...form.getInputProps("confirmPassword")}
                mb={20}
                radius={7}
              />

              <Button
                fullWidth
                mt="lg"
                size="md"
                style={{ backgroundColor: "#E27612" }}
                type="submit"
              >
                Submit
              </Button>
            </form>
            <Text align="center" mt="lg" color="#051C48">
              Don't have an account?
              <Link
                to="/NewAccount"
                weight={700}
                onClick={(event) => event.preventDefault()}
                style={{ textDecoration: "none", marginLeft: "5px" }}
              >
                Contact us
              </Link>
            </Text>
          </Container>
        </div>
        <div style={{ height: "100%", width: "90%" }}>
          <Bg />
        </div>
      </div>
    </>
  );
}
