import { Text, Flex, Menu } from "@mantine/core";
import { IconChevronDown, IconUserCircle } from "@tabler/icons";
import React, { useState } from "react";
import Newdesigntag from "../smallcomponents/Newdesigntag";

function AdminLogomobile() {
  // eslint-disable-next-line
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  return (
    <div>
      {" "}
      <Menu
        width={200}
        position="bottom-end"
        transition="pop-top-right"
        onClose={() => setUserMenuOpened(false)}
        onOpen={() => setUserMenuOpened(true)}
      >
        <Menu.Target>
          <Flex justify="flex-end" align="center" direction="row" wrap="wrap">
            <IconUserCircle
              radius="xl"
              color="#7C93BC"
              size={35}
            ></IconUserCircle>
            <IconChevronDown color="#7C93BC" size={15} stroke={4} />
          </Flex>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item>
          {localStorage.getItem("role") === "SuperAdmin"  ?  <Newdesigntag /> : <div></div>}
          </Menu.Item>
          <Menu.Item>
            <Text size={20} weight={500} align="center">
            {localStorage.getItem("role") === "SuperAdmin"
                  ? "Admin"
                  : "user"}
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}

export default AdminLogomobile;
