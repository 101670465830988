import React from "react";
import { Text, Menu, Flex, Button, Avatar } from "@mantine/core";
import { useState } from "react";
import useStyles from "../../../Style/UseStyle";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import avatar from "../../../../Images/avatar.svg";
import VectordownArreow from "../../../../Images/VectordownArreow.svg";

function Admindropdownmenu() {
  const { classes } = useStyles();
  const [first, setfirst] = useState("");

  useEffect(() => {
    const data = localStorage.getItem("name");

    setfirst(data);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  return (
    <>
      {" "}
      <div>
        {" "}
        <Menu
          width={260}
          position="bottom-end"
          transition="pop-top-right"
          onClose={() => setUserMenuOpened(false)}
          onOpen={() => setUserMenuOpened(true)}
        >
          <Menu.Target>
            <Flex
              mih={50}
              ml={20}
              // gap="xs"
              justify="flex-end"
              align="center"
              direction="row"
              // wrap="wrap"
              className={classes.pointer}
            >
              {" "}
              <Text
                weight={500}
                size="xl"
                sx={{ lineHeight: 1 }}
                mr={10}
                color="#7C93BC"
              >
                {localStorage.getItem("role") === "SuperAdmin" ? (
                  <Text size={17} weight={400}>
                    Admin
                  </Text>
                ) : (
                  <Text size={17} weight={400}>
                    {first}
                  </Text>
                )}
              </Text>
              {localStorage.getItem("role") === "SuperAdmin" ? (
                <>
                  {" "}
                  <img
                    src={avatar}
                    alt=""
                    srcset=""
                    style={{ marginRight: "5px" }}
                  />
                  {/* <Avatar variant="filled" radius={20} mr={5} /> */}
                </>
              ) : (
                <Avatar size={36} color="dark" radius={26} mr={4}>
                  <Text size={17} color="white" weight={500}>
                    {first.slice(0, 1).charAt(0).toUpperCase()}{" "}
                  </Text>
                </Avatar>
              )}
              <img
                src={VectordownArreow}
                alt=""
                srcset=""
                style={{ marginLeft: "2px" }}
              />
              {/* <IconChevronDown color="#7C93BC" size={15} stroke={4} /> */}
            </Flex>
          </Menu.Target>
          <Menu.Dropdown>
            {localStorage.getItem("role") === "SuperAdmin" ? (
              <div></div>
            ) : (
              <Menu.Item>
                <Flex justify="center" align="center">
                  <Link
                    to="/Useraccount"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    My Account
                  </Link>
                </Flex>
              </Menu.Item>
            )}

            <Menu.Item>
              <Flex justify="center" align="center">
                <Button
                  style={{ backgroundColor: "#E27612", borderRadius: "32px" }}
                  onClick={() => {
                    window.location.href = "/";
                    localStorage.clear();
                  }}
                >
                  <Text color="white" ml={5}>
                    Sign out
                  </Text>
                </Button>
              </Flex>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );
}

export default Admindropdownmenu;
