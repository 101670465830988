import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from "../../../Components/Style/UseStyle";
import {
  Text,
  TextInput,
  Button,
  ScrollArea,
  SimpleGrid,
  MultiSelect,
  Modal,
  Container,
  Flex,
  Input,
} from "@mantine/core";
import * as svg from "../Designs/svg";
import { Stage, Layer, Rect } from "react-konva";
import { useNavigate, useParams } from "react-router-dom";
import useImage from "use-image";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import CompanycolorContext from "../../../ContextFolder/CompanyColorContext/CompanycolorContext";
// import texture from "../../../Images/Texture.svg";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import { IconCirclePlus } from "@tabler/icons";
import { useDisclosure } from "@mantine/hooks";
function SavenewChanges() {
  const context = useContext(CatelogueContext);
  const { id } = useParams();
  const [colorMap, setColorMap] = useState([]);
  const [svgString, setSvgString] = useState("");
  const [valuee, setValue] = useState([]);
  // const [valuebyuser, setValuebyuser] = useState([]);

  const colors = svg.getColors(svgString);

  const [colorPickerSelectedColor, setColorPickerSelectedColor] =
    useState("#496738");
  const modifiedSVG = svg.replaceColors(svgString, colorMap);
  const [image] = useImage(svg.svgToURL(modifiedSVG));
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [opened, { close, open }] = useDisclosure(false);

  const showColorPicker = (toggle, oldColor) => {
    setDisplayColorPicker(toggle);
    setColorPickerSelectedColor(oldColor);
  };
  const [details, setDetails] = useState({
    name: "",
    tags: [],
    precolors: [],
  });

  const [defvaluelibraries, setDefvaluelibraries] = useState([]);
  const contextcolor = useContext(CompanycolorContext);
  const [active, setActive] = useState("");
  // eslint-disable-next-line
  const [libraries, setLibraries] = useState();
  const [dataa, setData] = useState([]);

  const { classes, cx } = useStyles();
  const [colordata, setColordata] = useState([]);
  const contextLib = useContext(LibraryContext);
  const [searchQuery, setSearchQuery] = useState("");

  const setNewColor = (oldColor, newColor) => {
    setColorMap({
      ...colorMap,
      [oldColor]: newColor,
    });
  };
  const divRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
    if (context?.getdesignbyid?.patternImage) {
      const fetchSVGString = async () => {
        setSvgString(context?.getdesignbyid?.patternImage);
      };
      fetchSVGString();
    }
    const getcolors = async () => {
      const companycolor = await contextcolor?.getallcolorsdataForsomePages();
      setColordata(companycolor.companyColors);
    };

    const getaDesignbyid = async () => {
      const data = await context.getdesignId(id);

      setDetails({
        name: data?.title,
        tags: data?.libraries,
        precolors: data?.colors,
      });

      const defaultvalue = data?.libraries?.map((ui) => `${ui.id}`);

      setDefvaluelibraries(defaultvalue);
      setValue(defaultvalue);
    };
    const getlibrary = async () => {
      const lib = await contextLib?.getlibraryforSomepage();
      const a = lib?.libraries?.map((ui) => {
        return { label: `${ui.name}`, value: `${ui.id}` };
      });
      setData(a);
      setLibraries(lib.libraries);
    };
    getaDesignbyid();
    getlibrary();
    getcolors();
    // getdesign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.getdesignbyid?.patternImage]);

  const addlibrary = async () => {
    await contextLib.addlibrary(first.name, first.status);
    setfirst({ name: "" });

    const lib = await contextLib?.getlibraryforSomepage();
    const addedlibrarysetfunction = lib?.libraries?.map((ui) => {
      return { label: `${ui.name}`, value: `${ui.id}` };
    });
    setData(addedlibrarysetfunction);
  };

  const handleClick = async () => {
    if (!details.name) {
      alert("please enter design name!");
    } else {
      await context.addDesign(details.name, data, modifiedSVG, valuee);

      navigate("/ArticleCard");
    }
  };

  const handleClickbyUser = async () => {
    if (!details.name) {
      alert("please enter design name!");
    } else {
      await context.addDesign(
        details.name,
        data,
        modifiedSVG,
        defvaluelibraries
      );

      navigate("/ArticleCard");
    }
  };

  const handle = async () => {
    if (!details.name) {
      alert("please enter design name!");
    } else {
      await context.editDesign(id, details.name, data, modifiedSVG, valuee);
      navigate("/ArticleCard");
    }
  };

  const handeldelete = async () => {
    const response = window.confirm(
      "Are you sure to delete this Design pattern?"
    );
    if (response) {
      const DeleteDesign = await context.DeleteDesign(id);
      if (DeleteDesign) {
        navigate("/ArticleCard");
      }
    } else {
      navigate(`/editpage/${id}`);
    }
  };

  const [first, setfirst] = useState({
    name: "",
    status: true,
  });
  const handleChange = (e) => {
    setfirst({ ...first, [e.target.name]: e.target.value });
  };

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  // eslint-disable-next-line
  const [scrolled, setScrolled] = useState(false);

  const data = colors?.map((color) => colorMap[color] || color);

  const filteredColors = colordata?.filter((rawcolor) =>
    rawcolor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const rawcolors = filteredColors.map((rawcolor) => (
    <>
      <div>
        <div
          key={rawcolor.id}
          style={{
            display: "flex",
            justifyContent: "normal",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              content: `${rawcolor.name}`,
              width: "24px",
              height: "24px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: `${rawcolor.colorCode}`,
            }}
            onClick={() => {
              setNewColor(colorPickerSelectedColor, rawcolor.colorCode);
            }}
          ></div>

          <Text ml={5}>{rawcolor.name}</Text>
        </div>
      </div>
    </>
  ));

  const navigate = useNavigate();

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          ref={divRef}
          style={{
            // padding: "5px",
            width: "70%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Stage width={dimensions.width} height={dimensions.height}>
            <Layer>
              <Rect
                width={dimensions.width}
                height={dimensions.height}
                fillPatternImage={image}
              />
            </Layer>
          </Stage>
        </div>{" "}
        {/* <div
          style={{
            background: `url(${texture})`,
            height: "100%",
            position: "absolute",
            width: "70%",
            top: "11%",
            left: "0",
          }}
          alt=""
        /> */}
        <div className={classes.d2c}>
          <div className={classes.colorchange}>
            <Text weight={600} color="#051C48">
              Colors
            </Text>
            <div className="p-2">
              <div style={{ display: "flex" }}>
                {colors.map((color) => {
                  const colorName = colordata.find(
                    (rawcolor) => rawcolor.colorCode === colorMap[color]
                  )?.name;

                  const targetColorCode = color;
                  const editData = colordata.find(
                    (rawcolor) =>
                      rawcolor.colorCode.toLowerCase() ===
                      targetColorCode.toLowerCase()
                  );
                  const editcolordata = editData ? editData.name : "";

                  return (
                    <div
                      key={color}
                      style={{
                        marginRight: "20px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "58px",
                          height: "58px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        className={cx({ [classes.active2]: color === active })}
                        onClick={() => {
                          showColorPicker(true, color);
                          setActive(color);
                        }}
                      >
                        <div
                          className={cx({ [classes.active]: color === active })}
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            backgroundColor: colorMap[color] || color,
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          marginTop: "2px",
                          wordBreak: "break-word",
                          width: "70px",
                        }}
                      >
                        <Text size={"sm"}>{colorName || editcolordata}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <Flex justify="space-between">
                <Text weight={600} mb={10} color="#051C48">
                  Choose Color
                </Text>
                <Input
                  type="text"
                  placeholder="Search color..."
                  hidden={!displayColorPicker}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  mb={"md"}
                />
              </Flex>
              {displayColorPicker && (
                <ScrollArea
                  sx={{ height: 170 }}
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    marginRight: "10px",
                  }}
                  onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                >
                  <SimpleGrid
                    cols={2}
                    spacing="xs"
                    breakpoints={[
                      { maxWidth: "md", cols: 2 },
                      { maxWidth: "sm", cols: 1 },
                    ]}
                  >
                    {rawcolors}
                  </SimpleGrid>
                </ScrollArea>
              )}

              {localStorage.getItem("role") === "SuperAdmin" ? (
                <>
                  {" "}
                  <form>
                    <div className="p-2">
                      <Text
                        weight={600}
                        mt={10}
                        mb={10}
                        size={18}
                        color="#051C48"
                      >
                        Design Details
                      </Text>
                      <TextInput
                        value={details.name}
                        name="name"
                        onChange={onChange}
                        label="Name"
                        mb={10}
                        placeholder="Trellise"
                      />
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <Text
                          weight={400}
                          mt={10}
                          mb={8}
                          size={13}
                          color="black"
                        >
                          Choose Library
                        </Text>
                        <MultiSelect
                          data={dataa}
                          value={valuee}
                          placeholder="Choose Library"
                          onChange={setValue}
                        />
                        <div
                          onClick={open}
                          style={{
                            display: "flex",
                            marginLeft: "5px",
                            marginTop: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <IconCirclePlus />
                          <Text color="black">Add Library</Text>
                        </div>
                      </div>
                      <div style={{ marginTop: "3%" }}>
                        <div style={{ width: "100%" }}>
                          {" "}
                          <Button
                            style={{ width: "30%" }}
                            variant="default"
                            mt={5}
                            color="#051C48"
                            radius="sm"
                            size="sm"
                            onClick={() => {
                              navigate("/ArticleCard");
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            style={{ width: "66%" }}
                            variant="default"
                            radius="sm"
                            size="sm"
                            color="#051C48"
                            ml={10}
                            onClick={handleClick}
                          >
                            Save as new design
                          </Button>
                        </div>

                        <Button
                          fullWidth
                          mt="xs"
                          size="sm"
                          style={{ backgroundColor: "#E27612" }}
                          radius="sm"
                          onClick={handle}
                        >
                          Save Changes
                        </Button>
                        <div
                          style={{
                            color: "#E27612",
                            fontWeight: "bold",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "15px",
                            fontSize: "15px",
                          }}
                          onClick={handeldelete}
                        >
                          <IconCirclePlus
                            style={{
                              transform: "rotate(315deg)",
                              marginRight: "5px",
                            }}
                          />
                          Delete Design
                        </div>
                      </div>
                    </div>
                  </form>
                  <Modal
                    opened={opened}
                    onClose={close}
                    size="xl"
                    title="Add library"
                  >
                    <Container size={700} py="xl">
                      {" "}
                      <Text>Add Library</Text>
                      <TextInput
                        // sx={{ flex: 1 }}
                        name="name"
                        value={first.name}
                        onChange={handleChange}
                        // {...form.getInputProps("name")}
                        mb={20}
                      />
                      <Button
                        onClick={addlibrary}
                        mt="sm"
                        style={{
                          backgroundColor: "#E27612",
                          marginLeft: "90%",
                        }}
                      >
                        Save
                      </Button>
                      {/* </form> */}
                    </Container>
                  </Modal>
                </>
              ) : (
                <div>
                  {" "}
                  <Text weight={600} mt={10} mb={10} size={18} color="#051C48">
                    Design Details
                  </Text>
                  <TextInput
                    value={details.name}
                    name="name"
                    onChange={onChange}
                    label="Name"
                    mb={10}
                    placeholder="Trellise"
                  />
                  <Button
                    fullWidth
                    variant="default"
                    mt={50}
                    color="#051C48"
                    radius="sm"
                    size="sm"
                    onClick={() => {
                      navigate("/ArticleCard");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    mt="xs"
                    size="sm"
                    style={{ backgroundColor: "#E27612" }}
                    radius="sm"
                    onClick={handleClickbyUser}
                  >
                    Save to my Library
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavenewChanges;
